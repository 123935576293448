<template>
<div class="news-list">

<com-header @clickMenu="clickMenu" :active_index="5"></com-header>

<div class="page-breadcrumb">
<span>当前位置：</span>
<router-link class="router-link" to="/">8455线路检测中心</router-link>
<span class="arrow-right">></span>
<span class="current-route">新闻资讯</span>
</div>
<div class="split-line"></div>
<div class="content" v-if="news_list.length">
<div class="news-list">
<div
          class="news-item"
          v-for="(item, index) in news_list"
          :key="index"
          @click="toDetail(item)"
        >
<img :src="item.img_url" alt="" class="news-img" />
<div class="news-title character-hiding-o">{{ item.title }}</div>
<div class="short-content character-hiding-two">
            {{ item.introduction }}
          </div>
<div class="bottom-info">
<span class="date">{{ item.created_at }}</span>
<span class="read-detail">阅读全文</span>
</div>
</div>
</div>
<div class="split-line"></div>
<div class="footer-pagation" v-if="total >0">
<div class="total">共{{ total }}条新闻，分{{ page_num }}页</div>
<div class="pagation">
<el-pagination
            layout="prev, pager, next"
            background
            :current-page="page"
            :prev-text="page == 1 ? '8455线路检测中心' : '上一页'"
            :page-size="12"
            next-text="下一页"
            @current-change="handlePageChange"
            :total="total"
          >
</el-pagination>
</div>
</div>
</div>

<div class="news-empty" v-else>
<el-empty description="暂无新闻~"></el-empty>
</div>

<com-footer @clickMenu="clickMenu"></com-footer>
</div>
</template>
<script>
import ComHeader from "@/components/layout/ComHeader";
import ComFooter from "@/components/layout/ComFooter";
import { getNewsList } from "@/api/home.js";

export default {
  data() {
    return {
      news_list: [],
      page: 1,
      total: 0,
      page_num: 0,
    };
  },
  components: {
    ComHeader,
    ComFooter,
  },
  mounted() {
    this.fetchNewsList();
  },
  methods: {
    clickMenu(e) {
      localStorage.setItem("menu_info", JSON.stringify(e));
      this.$router.push({ name: "home" });
    },
    //获取新闻列表
    fetchNewsList() {
      const params = {
        page: this.page,
        size: 12,
      };
      getNewsList(params).then((res) => {
        const { list, total } = res.data.data;
        list.forEach((item) => {
          item.created_at = item.created_at.split(" ")[0] || "--";
        });
        this.news_list = list || [];
        this.total = total;
        this.page_num = total < 12 ? 1 : Math.ceil(total / 12);
        window.scrollTo({ top: 0 });
      });
    },
    //去新闻详情
    toDetail(item) {
      const { news_id, type, url } = item;
      if (type == 2) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ name: "news-detail", query: { news_id } });
      }
    },
    //页数change事件
    handlePageChange(val) {
      this.page = val;
      this.fetchNewsList();
    },
  },
};
</script>
<style lang="scss" scoped>
.news-list {
  min-height: 60vh;
  .page-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
    padding-top: 140px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .router-link {
      font-size: 14px;
      color: #808080;
      &:hover {
        color: #22834c;
      }
    }
    .arrow-right {
      margin: 0 8px;
    }
    .current-route {
      color: #22834c;
    }
  }
  .content {
    max-width: 1200px;
    margin: 60px auto 0;
    .news-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .news-item {
        width: 374px;
        height: 400px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
        margin-bottom: 40px;
        margin-right: 39px;
        text-align: left;
        border: 2px solid transparent;
        cursor: pointer;
        transition: border 0.2s;
        &:hover {
          border: 2px solid #22834c;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .news-img {
          width: 334px;
          height: 224px;
          display: block;
        }
        .news-title {
          font-size: 18px;
          font-weight: 600;
          color: #201f1f;
          line-height: 25px;
          margin-top: 20px;
        }
        .short-content {
          font-size: 16px;
          color: #201f1f;
          line-height: 22px;
          height: 44px;
          margin-top: 10px;
        }
        .bottom-info {
          height: 27px;
          font-size: 14px;
          line-height: 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .date {
            color: #a5a5a5;
          }
          .read-detail {
            color: #22834c;
            font-weight: 600;
          }
        }
      }
    }
  }
  .split-line {
    max-width: 1200px;
    margin: 0 auto;
    height: 1px;
    background: #a9c0b3;
  }
  .footer-pagation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
    text-align: left;
    margin-top: 30px;
    position: relative;
    .pagation {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ::v-deep.el-pagination .btn-prev,
      ::v-deep.el-pagination .btn-next {
        width: 88px;
        height: 34px;
        line-height: 1;
        padding: 0;
        background: #ffffff;
        border: 1px solid #201f1f;
        font-size: 14px;
        font-weight: 400;
        color: #201f1f;
        text-align: center;
        &:disabled {
          border: 1px solid #808080;
          color: #808080;
        }
      }
      ::v-deep.el-pager li {
        height: 34px;
        line-height: 34px;
        padding: 0 14px;
        font-size: 14px;
        font-weight: 400;
        color: #201f1f;
        background: #ffffff;
        border: 1px solid #201f1f;
        border-radius: 0;
        &.active {
          height: 34px;
          line-height: 34px;
          color: #ffffff;
          background: #286141;
        }
        &.el-icon.more.btn-quicknext.el-icon-more,
        &.el-icon.more.btn-quickprev.el-icon-more {
          border: 0;
          height: 34px;
          line-height: 34px;
          text-align: center;
          padding: 0 14px;
        }
        &.el-icon.more.btn-quicknext:hover,
        &.el-icon.more.btn-quickprev:hover {
          height: 34px;
          line-height: 34px;
          text-align: center;
          padding: 0 14px;
          border: 0;
        }
      }
    }
  }
  .news-empty {
    padding-top: 140px;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
